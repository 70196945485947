@import (reference) "./core/standards.less";
.hero{
    max-height:890px;
    background-color:#ece4d7;
    position:relative;

    .decoration-1{
        position:absolute;
        bottom:0;
        right:0;
        z-index:2;
        height:100%;
    }

    .decoration-2{
        position:absolute;
        bottom:0;
        right:5%;
        z-index:3;
        height:100%;
    }


    .master-container{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "search" "imge";   
        position:relative;
        z-index:4;
        .search{
            grid-area:search;
            display:flex;
            flex-flow:column nowrap;
            align-items:center;
            justify-content: center;
            padding:475px 0;
            max-height:475px;
            h1{
                font-size:48px;
                color:@color-palette-dark-blue;
                line-height:1.2;
                font-weight: 900;
                width:100%;
            }
            form{
                display:flex;
                flex-flow:column nowrap;
                width:100%;
                input[type="submit"]{
                    width:100%;
                    display:block;
                    background-color:@color-palette-orange-var;
                    color:white;
                    letter-spacing:2px;
                    font-weight:600;
                    font-size:16px;
                    padding:10px;
                    border:0;
                    text-transform:uppercase;
                }
            }
            select{
                padding:10px;
                width:100%;
                display:block;
                margin-bottom:15px;
                border:0;
                color: #777777;
            }
        }

        .imge{
            grid-area:imge;
        }

    }

}

.read-more{
    margin-top:150px;
    padding-right:15px;
    h3{
        font-size:24px;
    }
    a{
        text-decoration: none;
        letter-spacing: 2px;
    }
}

.blockquote-cta{
    padding-top:0;
    .left-column{
        padding-right:15px;
    }

    .right-column{
        min-height:300px;
        width:100%;
        background-color:@color-support-10-var;
        padding:15px;
        font-size:18px;
        h2{
            font-size:24px;
        }
        .cta{
            text-decoration: none;
            display: block;
            margin:30px 0;
        }
    }

}

@media screen and (max-width:1000px) {
    
    .hero{
        overflow:hidden;

    
        .decoration-2{
            display:none;
        }
    
    
        .master-container{
            display:flex;
            flex-flow:column;
            align-items:center;
            justify-content: center;

            .search{
                max-height:initial;
                padding:110px 0;

                h1{
                    text-align:center;
                }
            }
        }

    }

}

@media screen and (max-width:768px){

    .read-more{
        margin-top:0;
        padding-right:0;
    }

    .square-cta{
        margin:auto;
        width:100%;
    }

    #quote {
        padding:0;
        .left-column{
            display: none;
        }
    }
}

@media screen and (max-width:570px){
    .long-search-cta{
        font-size:15px;
    }
    
    .long-search-cta form{
        flex-flow:column nowrap;
        background-color:transparent;
        select,input,input[type="submit"]{
            width:100%;
            margin:10px 0;
        }
    }
}


@media screen and (max-width:475px) {
    
    .hero{
    
        .decoration-2{
        }
    
    
        .master-container{
            .search{
                h1{
                    font-size:38px;
                }
            }
        }

    }

}


@media screen and (max-width:375px) {
    
    .hero{
    
        .decoration-2{
        }
    
    
        .master-container{
            .search{
                h1{
                    font-size:28px;
                }
                form{
                    input[type="submit"]{
                        font-size:14px;
                    }
                }
            }
        }

    }

}