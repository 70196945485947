.hero {
  max-height: 890px;
  background-color: #ece4d7;
  position: relative;
}
.hero .decoration-1 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  height: 100%;
}
.hero .decoration-2 {
  position: absolute;
  bottom: 0;
  right: 5%;
  z-index: 3;
  height: 100%;
}
.hero .master-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "search" "imge";
  position: relative;
  z-index: 4;
}
.hero .master-container .search {
  grid-area: search;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 475px 0;
  max-height: 475px;
}
.hero .master-container .search h1 {
  font-size: 48px;
  color: #034571;
  line-height: 1.2;
  font-weight: 900;
  width: 100%;
}
.hero .master-container .search form {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.hero .master-container .search form input[type="submit"] {
  width: 100%;
  display: block;
  background-color: var(--color-palette-orange);
  color: white;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  border: 0;
  text-transform: uppercase;
}
.hero .master-container .search select {
  padding: 10px;
  width: 100%;
  display: block;
  margin-bottom: 15px;
  border: 0;
  color: #777777;
}
.hero .master-container .imge {
  grid-area: imge;
}
.read-more {
  margin-top: 150px;
  padding-right: 15px;
}
.read-more h3 {
  font-size: 24px;
}
.read-more a {
  text-decoration: none;
  letter-spacing: 2px;
}
.blockquote-cta {
  padding-top: 0;
}
.blockquote-cta .left-column {
  padding-right: 15px;
}
.blockquote-cta .right-column {
  min-height: 300px;
  width: 100%;
  background-color: var(--color-support-10);
  padding: 15px;
  font-size: 18px;
}
.blockquote-cta .right-column h2 {
  font-size: 24px;
}
.blockquote-cta .right-column .cta {
  text-decoration: none;
  display: block;
  margin: 30px 0;
}
@media screen and (max-width: 1000px) {
  .hero {
    overflow: hidden;
  }
  .hero .decoration-2 {
    display: none;
  }
  .hero .master-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .hero .master-container .search {
    max-height: initial;
    padding: 110px 0;
  }
  .hero .master-container .search h1 {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .read-more {
    margin-top: 0;
    padding-right: 0;
  }
  .square-cta {
    margin: auto;
    width: 100%;
  }
  #quote {
    padding: 0;
  }
  #quote .left-column {
    display: none;
  }
}
@media screen and (max-width: 570px) {
  .long-search-cta {
    font-size: 15px;
  }
  .long-search-cta form {
    flex-flow: column nowrap;
    background-color: transparent;
  }
  .long-search-cta form select,
  .long-search-cta form input,
  .long-search-cta form input[type="submit"] {
    width: 100%;
    margin: 10px 0;
  }
}
@media screen and (max-width: 475px) {
  .hero .master-container .search h1 {
    font-size: 38px;
  }
}
@media screen and (max-width: 375px) {
  .hero .master-container .search h1 {
    font-size: 28px;
  }
  .hero .master-container .search form input[type="submit"] {
    font-size: 14px;
  }
}
